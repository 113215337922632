import { alertTypes } from "../../types/alert/types";
// ** Initial State
const initialState = {};

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case alertTypes.alertLoad:
            return {
                ...state,
                data: [...(action.payload ? action.payload : [])],
            };

        case alertTypes.alertDelete:
            return {
                ...state,
                data: state.data.filter((reg) => reg.id !== action.payload),
            };
        default:
            return state;
    }
};

export default alertReducer;
