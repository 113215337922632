// You can customize the template with the help of this file

//Template config options
const themeConfig = {
    app: {
        appName: "",
        appLogoCollapsedImage: require("@src/assets/images/logo/LOGO-CONMED-COLLAPSED.png").default,
        appLogoImage: {
            light: require("@src/assets/images/logo/LOGO-CONMED.png").default,
            dark: require("@src/assets/images/logo/LOGO-CONMED-WHITE.png").default,
        },
    },
    layout: {
        isRTL: false,
        skin: "light", // light, dark, bordered, semi-dark
        routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: "vertical", // vertical, horizontal
        contentWidth: "full", // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: "floating", // static , sticky , floating, hidden
            backgroundColor: "white", // BS color options [primary, success, etc]
        },
        footer: {
            type: "hidden", // static, sticky, hidden
        },
        customizer: true,
        scrollTop: true, // Enable scroll to top button
        isInForm: false,
    },
};

export default themeConfig;
